import React, { useCallback } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { home } from '@/model';
import { apiGetUserinfo } from '@/service';
import { useSetRecoilState } from 'recoil';
import { useMount } from 'ahooks';
import lazyLoad from '@/utils/lazyLoad';
import Loading from '@/components/Loading';
import Home from './Home';
import HelperSearch from './HelperSearch';

const {
  Login,
  DDoc,
  BDoc,
  Guide,
  Preview,
  ProjectBox,
  PackageBox,
  PBTool,
  BatchBox,
  BatchPkg,
  WorkbenchNext,
  SearchResult,
  Helper,
  E404,
  Notification,
  TeamJoinApply,
  UpdatePwd,
  Profile,
  TourVideo,
} = lazyLoad({
  Login: () => import('./Login'),
  WorkbenchNext: () => import('./WorkbenchNext'),
  DDoc: () => import('./DDoc'),
  BDoc: () => import('./BDoc'),
  Guide: () => import('./Guide'),
  Preview: () => import('./Preview'),
  ProjectBox: () => import('./ProjectBox'),
  BatchBox: () => import('./BatchBox'),
  BatchPkg: () => import('./BatchPkg'),
  PackageBox: () => import('./PackageBox'),
  SearchResult: () => import('./SearchResult'),
  Helper: () => import('./Helper'),
  HelperSearch: () => import('./HelperSearch'),
  PBTool: () => import('./PBTool'),
  E404: () => import('./E404'),
  Notification: () => import('./Notification'),
  TeamJoinApply: () => import('./TeamJoinApply'),
  UpdatePwd: () => import('./WorkbenchNext/UpdatePwd'),
  Profile: () => import('./WorkbenchNext/Profile'),
  TourVideo: () => import('./TourVideo'),
});

export default function Routers() {
  const setUserInfo = useSetRecoilState(home.userInfo);

  const getUserInfo = useCallback(async () => {
    // 因为初始就调用，要保持静默，不弹出错误提示
    const { data, err } = await apiGetUserinfo();
    if (err) {
      // 清空用户信息
      setUserInfo({});
      return;
    }
    setUserInfo(data);
  }, [setUserInfo]);

  useMount(() => {
    getUserInfo();
  });

  return (
    <React.Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/home" />
          </Route>
          <Route path="/workbench-next">
            <WorkbenchNext />
          </Route>
          <Route path="/guide/:type/:fileTypeId?">
            <Guide />
          </Route>
          {/* 模板编辑器 */}
          <Route path="/ddoc/:fileType/:fileUuid/:fileVersionId?">
            <DDoc />
          </Route>
          {/* 模板文库-只读 */}
          <Route path="/library/:fileType/:fileUuid/:fileVersionId?">
            <DDoc readonly />
          </Route>
          {/* 文件编辑器 */}
          <Route path="/bdoc/:fileType/:fileUuid/:fileVersionId?">
            <BDoc />
          </Route>
          <Route path="/team-join-apply/:fileUuid/:fileVersionId">
            <TeamJoinApply />
          </Route>
          <Route path="/project/:projectId">
            <ProjectBox />
          </Route>
          <Route path="/preview/:fileUuid/:fileVersionId?">
            <Preview downloadCenterUrl="/workbench-next/download-center" />
          </Route>
          <Route path="/batch/:batchId">
            <BatchBox />
          </Route>
          <Route path="/package/:batchId/:packageId">
            <BatchPkg />
          </Route>
          <Route path="/project-nest/:projectId/:packageId">
            <PackageBox />
          </Route>
          <Route path="/search-result">
            <SearchResult />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/helper">
            <Helper />
          </Route>
          <Route path="/helper-search">
            <HelperSearch />
          </Route>
          <Route path="/pb-tool/:pbId">
            <PBTool />
          </Route>
          <Route path="/notification">
            <Notification />
          </Route>
          <Route path="/update-pwd">
            <UpdatePwd />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/tour-video">
            <TourVideo />
          </Route>
          <Route path="*">
            <E404 />
          </Route>
        </Switch>
      </BrowserRouter>
    </React.Suspense>
  );
}
