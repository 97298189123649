import request from '@/utils/request';

export * from './ddoc';
export * from './mySpace';
export * from './login';
export * from './information';
export * from './profile';
export * from './platformM8t';
export * from './guide';
export * from './bdoc';
export * from './preview';
export * from './tdoc';
export * from './myProject';
export * from './docLibrary';
export * from './home';
export * from './batchBox';
export * from './helper';
export * from './fileSpace';
export * from './toolPermissionM8t';
export * from './docCheck';
export * from './diff';
export * from './documentPro';

/**
 * @description 获取选项列表
 * @export
 * @param {object} data
 * @param {string} data.type
 * @return {{label: string, value: string}[]}
 */
export function apiGetOptions(params) {
  return request({
    method: 'get',
    url: '/worktable/getOptions',
    params,
  });
}

/**
 * @description 记录报错日志
 * @export
 * @param {object} data
 * @param {string} data.userId
 * @param {string} data.href
 * @param {string} data.errorType
 * @param {string} data.error
 * @param {string} data.browser
 */
export function apiDevTrigger(data) {
  return request({
    method: 'post',
    url: '/worktable/saveErrLog',
    data,
  });
}
