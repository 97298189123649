import request from '@/utils/request';

/**
 * @description 提交参数值
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.paramsUuid
 * @param {string} data.outlineId
 * @param {string} data.contentText
 * @param {string} data.paramsText
 * @param {any[]} data.paramsTextList
 * @return {*}
 */
export function apiSyncParam(data) {
  return request({
    method: 'post',
    url: '/web/file/writeParam',
    data,
  });
}

/**
 * @description 文件保存
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @return {*}
 */
export function apiSaveBiddingDoc(data) {
  return request({
    method: 'post',
    url: '/web/file/saveWriteParam',
    data,
  });
}

/**
 * @description 获取相关表的上卷表
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string[]} data.oriDataSource // 原始表
 */
export function apiGetRollTableOptions(data) {
  return request({
    method: 'post',
    url: '/web/file/getRollTableList',
    data,
  });
}

/**
 * @description 获取文内数据表数据的所有索引数据，用户全选
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.key table id
 * @param {string} data.filter
 */
export function apiGetDbTableKeyList(data) {
  return request({
    method: 'post',
    url: '/worktable/getTotalKeys',
    data,
  });
}

/**
 * @description 删除附件
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @param {string} params.paramsUuid
 * @param {string} params.uid 附件唯一标识
 * @return {*}
 */
export function apiDeleteParamAnnex(params) {
  return request({
    method: 'get',
    url: '/web/file/deleteAnnexParam',
    params,
  });
}

/**
 * @description 获取招标信息
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @return {*}
 */
export function apiGetFileBiddingInfo(params) {
  return request({
    method: 'get',
    url: '/web/file/getBidLeftInfo',
    params,
  });
}

/**
 * @description 修改招标信息
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {array} data.biddingType
 * @param {array} data.biddingStyle
 * @param {array} data.judgmentMethod
 * @return {*}
 */
export function apiUpdateBiddingInfo(data) {
  return request({
    method: 'post',
    url: '/web/file/updateBidLeftInfo',
    data,
  });
}

/**
 * @description 单位换算
 * @export
 * @param {object} data
 * @param {string} data.value 参考值
 * @param {string} data.unitName 参考单位
 * @param {string} data.tagUnitName 目标单位
 * @return {*}
 */
export function apiUnitTransform(data) {
  return request({
    method: 'post',
    url: '/web/file/unitTrans',
    data,
  });
}

/**
 * @description 获取参数详情
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.uuid 参数uuid
 * @return {*}
 */
export function apiGetParameterDetail(data) {
  return request({
    method: 'post',
    url: '/web/file/getParamDetail',
    data,
  });
}

/**
 * @description 文件投递
 * @export
 * @param {object} data
 * @param {string} data.projectId
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @return {*}
 */
export function apiPostFile(data) {
  return request({
    method: 'post',
    url: '/web/file/newDeliverFile',
    data,
  });
}

/**
 * @description 获取文件所处项目中的文件
 * @export
 * @param {object} params
 * @param {string} params.projectId
 * @return {*}
 */
export function apiGetProjectFiles(params) {
  return request({
    method: 'get',
    url: '/web/file/getProjectMateFile',
    params,
  });
}

/**
 * @description 组合文件，替换章节
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.outlineId
 * @param {string} data.fileUuid2
 * @param {array} data.outlineIdList2
 * @param {boolean} data.includeParam
 * @return {*}
 */
export function apiFileCombination(data) {
  return request({
    method: 'post',
    url: '/web/file/newCompoundFile',
    data,
  });
}

/**
 * @description 获取投递记录
 * @export
 * @param {*} params
 * @return {*}
 */
export function apiGetPostRecord(params) {
  return request({
    method: 'get',
    url: '/web/file/getDeliverRec',
    params,
  });
}

/**
 * @description 获取辅助工具列表
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @return {*}
 */
export function apiGetAutoInputList(params) {
  return request({
    method: 'get',
    url: '/web/file/getAutoInputList',
    params,
  });
}

/**
 * @description 辅助工具-自动填写
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.formIdMap 表单数据
 * @return {*}
 */
export function apiAutoInput(data) {
  return request({
    method: 'post',
    url: '/web/file/autoInput',
    data,
  });
}

/**
 * @description 辅助工具-自动填写 新
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.formI 表单数据
 * @return {*}
 */
export function apiAutoInputNew(data) {
  return request({
    method: 'post',
    url: '/worktable/supAutoWrite',
    data,
  });
}

/**
 * @description 获取参数填写记录
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.paramsUuid
 * @return {*}
 */
export function apiGetParamsHistory(data) {
  return request({
    method: 'post',
    url: '/web/file/getParamChangeHistory',
    data,
  });
}

/**
 * @description 清除参数填写记录
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @return {*}
 */
export function apiClearParamsHistory(data) {
  return request({
    method: 'post',
    url: '/web/file/clearParamChangeHistory',
    data,
  });
}

/**
 * @description 获取辅助填写的模型数据源tree
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetFillInHelperDataSource(data) {
  return request({
    method: 'post',
    url: '/worktable/getSupTableTree',
    data,
  });
}

/**
 * @description 获取自动填写最近使用的数据集
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetAutoFillingRecentDS(data) {
  return request({
    method: 'post',
    url: '/worktable/getSupTableRecent',
    data,
  });
}

/**
 * @description 自动填写
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {object} data.retList 填写结果
 * @return {*}
 */
export function apiSubmitAutoFilling(data) {
  return request({
    method: 'post',
    url: '/worktable/supAutoWriteSure',
    data,
  });
}

/**
 * @description 获取自动填写历史记录 5条
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @return {*}
 */
export function apiGetAutoFillingHistoryList(data) {
  return request({
    method: 'post',
    url: '/worktable/getSupTableHistoryList',
    data,
  });
}

/**
 * @description 将文内数据保存为excel附件
 * @export
 * @param {object} data
 * @param {string} data.tableKey database表id
 * @param {string[]} data.fields 数据库字段
 * @param {string[]} data.rows 数据库数据
 */
export function apiSaveDatabaseToExcel(data) {
  return request({
    method: 'post',
    url: '/worktable/uploadAnnexParamByDm',
    data,
  });
}

/**
 * @description 获取用户登录状态
 * @export
 * @param {object} data
 * @param {string} data.userId
 */
export function apiGetUserLoginStatus(params) {
  return request({
    method: 'get',
    url: '/web/teamWork/getUserStatus',
    params,
  });
}

/**
 * @description 创建协作空间
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.userList
 */
export function apiCreateCollaborationRoom(data) {
  return request({
    method: 'post',
    url: '/web/teamWork/createHfRoom',
    data,
  });
}

/**
 * @description 获取协作空间信息
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 */
export function apiGetCollaborationRoomInfo(data) {
  return request({
    method: 'post',
    url: '/web/teamWork/getHfRoomInfo',
    data,
  });
}

/**
 * @description 移除协作用户
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.noticeUserId
 */
export function apiRemoveCollaborator(data) {
  return request({
    method: 'post',
    url: '/web/teamWork/removeUser',
    data,
  });
}

/**
 * @description 更新协作者信息
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.noticeUserId
 * @param {string} data.permission
 */
export function apiUpdateCollaborator(data) {
  return request({
    method: 'post',
    url: '/web/teamWork/changeUserPermission',
    data,
  });
}

/**
 * @description 邀请协作者：由于是管理员邀请，所以用户加入不再需要管理员同意
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {object[]} data.inviteUserList
 */
export function apiInviteCollaborator(data) {
  return request({
    method: 'post',
    url: '/web/teamWork/inviteUser',
    data,
  });
}

/**
 * @description 用户申请加入协同：需要管理员同意
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 */
export function apiUserJoinApplication(data) {
  return request({
    method: 'post',
    url: '/web/teamWork/userJoinApplication',
    data,
  });
}

/**
 * @description
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.applyUserId
 * @param {boolean} data.result
 */
export function apiApproveJoinApplication(data) {
  return request({
    method: 'post',
    url: '/web/teamWork/handleJoinApplication',
    data,
  });
}

/**
 * @description 销毁协作空间
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 */
export function apiDestroyCollaboration(data) {
  return request({
    method: 'post',
    url: '/web/teamWork/closeHfRoom',
    data,
  });
}

/**
 * @description 更新协作空间设置
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {object} data.settings
 */
export function apiUpdateRoomSettings(data) {
  return request({
    method: 'post',
    url: '/web/teamWork/updateHfRoom',
    data,
  });
}
