import { atom, selector, waitForAll } from 'recoil';
import { message } from 'antd';
import {
  apiGetParamGroupList,
  apiGetWordList,
  apiGetLabelList,
  apiGetLabelGroupList,
  apiGetParamSaturationList,
  apiGetOriginalTempPageList,
  apiGetFileM8tList,
  apiFileAuditList,
  apiGetUserTableList,
  apiGetRolesLV,
  apiGetOptions,
  apiGetLabelLV,
  apiGetEvaluateModelInfo,
  apiGetEvaluateModelList,
  apiGetEvaluateModelElementList,
  apiGetEvaluateModelOptions,
  apiGetEvaluateInstanceFactorList,
  apiGetEvaluateInstanceTabList,
  apiGetParameterOptions,
  apiGetDocParameterOptions,
  apiGetStandardTypeOptions,
  apiGetDocTypeOptions,
  apiGetEnterpriseCommonFileList,
  apiGetSystemOptionList,
  apiGetSystemOptionTypeList,
} from '@/service';

const platformM8tRecoil = {
  params4ParamGroupList: atom({
    key: 'params4ParamGroupList',
    default: null,
  }), // 参数分组
  params4WordList: atom({
    key: 'params4WordList',
    default: null,
  }), // 词条
  params4DocM8tList: atom({
    key: 'params4DocM8tList',
    default: null,
  }), // 文档管理列表
  params4DocAuditList: atom({
    key: 'params4DocAuditList',
    default: null,
  }), // 文档审核列表
  params4UserList: atom({
    key: 'params4UserList',
    default: null,
  }),
  params4LabelList: atom({
    key: 'params4LabelList',
    default: null,
  }),
  params4LabelGroupList: atom({
    key: 'params4LabelGroupList',
    default: null,
  }),
  params4EnterpriseCommonFileList: atom({
    key: 'params4EnterpriseCommonFileList',
    default: null,
  }),
  params4OriginalTempPageList: atom({
    key: 'params4OriginalTempPageList',
    default: null,
  }),
  params4evaluateList: atom({
    key: 'params4evaluateList',
    default: null,
  }),
  params4evaluateInfo: atom({
    key: 'params4evaluateInfo',
    default: null,
  }),
  params4evaluateElement: atom({
    key: 'params4evaluateElement',
    default: null,
  }),
  params4evaluateOptions: atom({
    key: 'params4evaluateOptions',
    default: null,
  }),
  params4evaluateInstanceFactorList: atom({
    key: 'params4evaluateInstanceFactorList',
    default: null,
  }),
  params4evaluateInstanceTabList: atom({
    key: 'params4evaluateInstanceTabList',
    default: null,
  }),
  params4docParamOptions: atom({
    key: 'params4docParamOptions',
    default: null,
  }),
  params4docStandardTypeOptions: atom({
    key: 'params4docStandardTypeOptions',
    default: null,
  }),
  params4DocTypeList: atom({
    key: 'params4DocTypeList',
    default: null,
  }),
  params4SystemOptionList: atom({
    key: 'params4SystemOptionList',
    default: null,
  }),
  paramGroupList: selector({
    key: 'paramGroupList',
    get: async ({ get }) => {
      const { params4ParamGroupList } = platformM8tRecoil;
      const params = get(params4ParamGroupList);
      if (!params) return null;
      const { data, err } = await apiGetParamGroupList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (params.isPaged === '0') {
        return data;
      }
      const { list = [], total = 0 } = data || {};
      return { list, total, ...params };
    },
  }),
  wordList: selector({
    key: 'wordList',
    get: async ({ get }) => {
      const { params4WordList } = platformM8tRecoil;
      const params = get(params4WordList);
      if (!params) return null;
      const { data, err } = await apiGetWordList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (params.isPaged === '0') {
        return data;
      }
      const { list = [], total = 0 } = data || {};
      return { list, total, ...params };
    },
  }),
  labelList: selector({
    key: 'labelList',
    get: async ({ get }) => {
      const { params4LabelList } = platformM8tRecoil;
      const params = get(params4LabelList);
      if (!params) return null;
      const { data, err } = await apiGetLabelList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (params.isPaged === '0') {
        return data;
      }
      const { list = [], total = 0 } = data || {};
      return { list, total, ...params };
    },
  }),
  labelGroupList: selector({
    key: 'labelGroupList',
    get: async ({ get }) => {
      const { params4LabelGroupList } = platformM8tRecoil;
      const params = get(params4LabelGroupList);
      if (!params) return null;
      const { data, err } = await apiGetLabelGroupList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  paramSaturationList: selector({
    key: 'paramSaturationList',
    get: async () => {
      const { data, err } = await apiGetParamSaturationList();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  originTempPageList: selector({
    key: 'originTempList',
    get: async ({ get }) => {
      const { params4OriginalTempPageList } = platformM8tRecoil;
      const params = get(params4OriginalTempPageList);
      if (!params) return null;
      const { data, err } = await apiGetOriginalTempPageList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  docM8tList: selector({
    key: 'docM8tList',
    get: async ({ get }) => {
      const { params4DocM8tList } = platformM8tRecoil;
      const params = get(params4DocM8tList);
      if (!params) return null;
      const { data, err } = await apiGetFileM8tList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (params.isPaged === '0') {
        return data;
      }
      const { list = [], total = 0 } = data || {};
      return { list, total, ...params };
    },
  }),
  docAuditList: selector({
    key: 'docAuditList',
    get: async ({ get }) => {
      const { params4DocAuditList } = platformM8tRecoil;
      const params = get(params4DocAuditList);
      if (!params) return null;
      const { data, err } = await apiFileAuditList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (params.isPaged === '0') {
        return data;
      }
      const { list = [], total = 0 } = data || {};
      return { list, total, ...params };
    },
  }),
  userTableList: selector({
    key: 'userTableList',
    get: async ({ get }) => {
      const { params4UserList } = platformM8tRecoil;
      const params = get(params4UserList);
      if (!params) return null;
      const { data, err } = await apiGetUserTableList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (params.isPaged === '0') {
        return data;
      }
      const { list = [], total = 0 } = data || {};
      return { list, total, ...params };
    },
  }),
  rolesLVList: selector({
    key: 'rolesLVList',
    get: async () => {
      const { data, err } = await apiGetRolesLV();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  labelLVList: selector({
    key: 'labelLVList',
    get: async () => {
      const { data, err } = await apiGetLabelLV();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  // 下拉选项：评审方法
  evaluateMethodOptions: selector({
    key: 'evaluateMethodOptions',
    get: async () => {
      const { data, err } = await apiGetOptions({ type: 'assessMethod' });
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  // 下拉选项：评审环节
  evaluatePartOptions: selector({
    key: 'evaluatePartOptions',
    get: async () => {
      const { data, err } = await apiGetOptions({ type: 'assessLink' });
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  // 下拉选项：评审类型
  evaluateTypeOptions: selector({
    key: 'evaluateTypeOptions',
    get: async () => {
      const { data, err } = await apiGetOptions({ type: 'assessType' });
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  // 下拉选项：模型类型
  evaluateModelTypeOptions: selector({
    key: 'evaluateModelTypeOptions',
    get: async () => {
      const { data, err } = await apiGetOptions({ type: 'modelType' });
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  // 下拉选项：评审标准类型
  standardTypeOptions: selector({
    key: 'standardTypeOptions',
    get: async () => {
      const { data, err } = await apiGetOptions({ type: 'elementType' });
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  // 下拉选项：报价评审方案
  priceAssessOptions: selector({
    key: 'priceAssessOptions',
    get: async () => {
      const { data, err } = await apiGetOptions({ type: 'priceAssessPlan' });
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  // 下拉选项：根据评审因素动态获取
  evaluateList: selector({
    key: 'evaluateList',
    get: async ({ get }) => {
      const { params4evaluateList } = platformM8tRecoil;
      const params = get(params4evaluateList);
      if (!params) return null;
      const { data, err } = await apiGetEvaluateModelList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (params.isPaged === '0') {
        return data;
      }
      const { list = [], total = 0 } = data || {};
      return { list, total, ...params };
    },
  }),
  // 下拉选项：维护
  systemOptionList: selector({
    key: 'systemOptionList',
    get: async ({ get }) => {
      const { params4SystemOptionList } = platformM8tRecoil;
      const params = get(params4SystemOptionList);
      if (!params) return null;
      const { data, err } = await apiGetSystemOptionList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (params.isPaged === '0') {
        return data;
      }
      const { list = [], total = 0 } = data || {};
      return { list, total, ...params };
    },
  }),
  // 下拉选项类型：维护
  systemOptionTypeList: selector({
    key: 'systemOptionTypeList',
    get: async () => {
      const { data, err } = await apiGetSystemOptionTypeList();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  docStandardTypeOptions: selector({
    key: 'docStandardTypeOptions',
    get: async ({ get }) => {
      const { params4docStandardTypeOptions } = platformM8tRecoil;
      const params = get(params4docStandardTypeOptions);
      if (!params) return null;
      const { payload, success } = params;
      const { data, err } = await apiGetStandardTypeOptions(payload);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (success) success(data);
      return data;
    },
  }),
  evaluateOptions: selector({
    key: 'evaluateOptions',
    get: async ({ get }) => {
      const { params4evaluateOptions } = platformM8tRecoil;
      const params = get(params4evaluateOptions);
      if (!params) return null;
      const { data, err } = await apiGetEvaluateModelOptions(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  evaluateInstanceFactorList: selector({
    key: 'evaluateInstanceFactorList',
    get: async ({ get }) => {
      const { params4evaluateInstanceFactorList } = platformM8tRecoil;
      const params = get(params4evaluateInstanceFactorList);
      if (!params) return null;
      const { data, err } = await apiGetEvaluateInstanceFactorList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  evaluateInstanceTabList: selector({
    key: 'evaluateInstanceTabList',
    get: async ({ get }) => {
      const { params4evaluateInstanceTabList } = platformM8tRecoil;
      const params = get(params4evaluateInstanceTabList);
      if (!params) return null;
      const { payload, success } = params;
      const { data, err } = await apiGetEvaluateInstanceTabList(payload);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (success) success(data);
      return data;
    },
  }),
  evaluateInfo: selector({
    key: 'evaluateInfo',
    get: async ({ get }) => {
      const { params4evaluateInfo } = platformM8tRecoil;
      const params = get(params4evaluateInfo);
      if (!params) return null;
      const { data, err } = await apiGetEvaluateModelInfo(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  evaluateElementList: selector({
    key: 'evaluateElementList',
    get: async ({ get }) => {
      const { params4evaluateElement } = platformM8tRecoil;
      const params = get(params4evaluateElement);
      if (!params) return null;
      const { data, err } = await apiGetEvaluateModelElementList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (params.isPaged === '0') {
        return data;
      }
      const { list = [], total = 0 } = data || {};
      return { list, total, ...params };
    },
  }),
  tbrParamOptions: selector({
    key: 'tbrParamOptions',
    get: async () => {
      const { data, err } = await apiGetParameterOptions({ paramsUseSceneId: '20' });
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  zbrParamOptions: selector({
    key: 'zbrParamOptions',
    get: async () => {
      const { data, err } = await apiGetParameterOptions({ paramsUseSceneId: '10' });
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  tbrDocParamOptions: selector({
    key: 'tbrDocParamOptions',
    get: async ({ get }) => {
      const { params4docParamOptions } = platformM8tRecoil;
      const params = get(params4docParamOptions);
      if (!params) return null;
      const { data, err } = await apiGetDocParameterOptions({
        ...params,
        paramsUseSceneId: '20',
      });
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  zbrDocParamOptions: selector({
    key: 'zbrDocParamOptions',
    get: async ({ get }) => {
      const { params4docParamOptions } = platformM8tRecoil;
      const params = get(params4docParamOptions);
      if (!params) return null;
      const { data, err } = await apiGetDocParameterOptions({
        ...params,
        paramsUseSceneId: '10',
      });
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  docParamOptions: selector({
    key: 'docParamOptions',
    get: async ({ get }) => {
      const { tbrDocParamOptions, zbrDocParamOptions } = platformM8tRecoil;
      const res = get(
        waitForAll({
          zbr: zbrDocParamOptions,
          tbr: tbrDocParamOptions,
        }),
      );
      return res;
    },
  }),
  docTypeFileList: selector({
    key: 'docTypeFileList',
    get: async ({ get }) => {
      const { params4DocTypeList } = platformM8tRecoil;
      const params = get(params4DocTypeList);
      if (!params) return null;
      const { data, err } = await apiGetDocTypeOptions(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  enterpriseCommonList: selector({
    key: 'enterpriseCommonList',
    get: async ({ get }) => {
      const { params4EnterpriseCommonFileList } = platformM8tRecoil;
      const params = get(params4EnterpriseCommonFileList);
      if (!params) return null;
      const { data, err } = await apiGetEnterpriseCommonFileList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
};

export default platformM8tRecoil;
