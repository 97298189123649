import ReactDOM from 'react-dom';

/**
 * @description 判断是否是长度大于0的数组
 * @param {Array} arr
 * @return {boolean}
 */
export const isPowerArray = (arr) => Array.isArray(arr) && arr.length > 0;

/**
 * @description 判断是否空对象
 * @param {object} obj
 * @return {boolean}
 */
export const isPowerObject = (obj) => Object.prototype.toString.call(obj).indexOf('Object') > -1
  && Object.keys(obj).length > 0;

/**
 * @description 判断是否对象
 * @param {object} obj
 * @return {boolean}
 */
export const isObject = (obj) => Object.prototype.toString.call(obj).indexOf('Object') > -1;

/**
 * @description 判断是否FormData
 * @param {object} obj
 * @return {boolean}
 */
export const isFormData = (obj) => Object.prototype.toString.call(obj).indexOf('FormData') > -1;

/**
 * @description 判断目标是否存在
 * @param {*} v
 * @return {boolean}
 */
export const isExist = (v) => v !== undefined && v !== null && v !== '';

/**
 * @description 判断目标是否是函数
 * @param {*} v
 * @return {boolean}
 */
export const isFunction = (v) => typeof v === 'function';

/**
 * @description 判断目标是否是Promise
 * @param {*} v
 * @return {boolean}
 */
export const isPromise = (v) => isFunction(v.then) && isFunction(v.catch);

/**
 * @description 判断目标是否是JSON
 * @param {string} str
 * @return {boolean}
 */
export const isJSON = (str) => {
  if (typeof str === 'string') {
    try {
      return Boolean(JSON.parse(str));
    } catch (e) {
      return false;
    }
  }
  return false;
};

/**
 * @description 获取目标节点离顶部距离
 * @export
 * @param {*} ele
 * @param {*} offsetEle
 * @return {*}
 */
export function getRelativeOffsetTop(ele, offsetEle) {
  if (!(ele instanceof HTMLElement)) return false;
  let actualTop = ele.offsetTop;
  let relativeDom = ele.offsetParent;
  while (relativeDom !== null && !relativeDom?.isEqualNode(offsetEle)) {
    actualTop += relativeDom.offsetTop;
    relativeDom = relativeDom.offsetParent;
  }
  return actualTop;
}

/**
 * @description 反序列化数组
 * @param {*} arrStr
 * @return {*}
 */
export const deserializeArr = (arrStr) => {
  let arr = [];
  try {
    arr = JSON.parse(decodeURI(arrStr));
  } catch (err) {
    arr = [];
  }
  return arr;
};

/**
 * @description 是否是有效值
 * @export
 * @param {*} value
 * @return {Boolean}
 */
export function isPowerValue(value) {
  let bool;
  if (isExist(value)) {
    if (Array.isArray(value) && !isPowerArray(value)) {
      // 空数组
      bool = false;
    } else if (isObject(value) && !isPowerObject(value)) {
      // 空对象
      bool = false;
    } else {
      // 有效值
      bool = true;
    }
  } else {
    // 不存在
    bool = false;
  }
  return bool;
}

export function objPropertyNullToUndefined(obj) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      newObj[key] = undefined;
    } else if (isObject(obj[key])) {
      newObj[key] = objPropertyNullToUndefined(obj[key]);
    } else {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

export function getFileNameFromUrl(url) {
  // 获取路径名并分割成数组
  const pathParts = url?.split?.('/');

  if (!pathParts) return null;

  // 返回数组的最后一个元素（即文件名）
  return pathParts[pathParts.length - 1];
}

/**
 * @description 兼容性浏览器访问剪贴板
 * @export
 * @param {*} text
 * @return {*}
 */
export function copyToClipboard(text) {
  // 浏览器安全性限制，导致只有https安全域下才可以访问 navigator.clipboard
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(text);
  }
  // text area method
  const textArea = document.createElement('textarea');
  textArea.value = text;
  // make the textarea out of viewport
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  return new Promise((resolve, reject) => {
    // here the magic happens
    const copied = document.execCommand('copy');
    if (copied) {
      resolve();
    } else {
      reject();
    }
    textArea.remove();
  });
}

/**
 * @description 下载word文件流
 * @export
 * @param {*} blob
 * @param {*} fileName
 */
export function downloadBlobWordFile(blob, fileName, type = 'application/msword') {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(new Blob([blob], { type }));
  if (fileName) {
    link.download = fileName;
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
}

/**
 * @description 下载excel文件流
 * @export
 * @param {*} blob
 * @param {*} fileName
 */
export function downloadBlobExcelFile(blob, fileName) {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(
    new Blob([blob], { type: 'application/vnd.ms-excel' }),
  );
  link.download = `${fileName}.xls`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
}

/**
 * @description 给tree生成索引
 * @export
 * @param {*} treeData
 * @param {*} [path]
 * @return {*}
 */
export function treeIndexGenerator(treeData, path) {
  if (!Array.isArray(treeData)) return null;
  return treeData.map((item, i) => {
    const _path = !path ? [i] : [...path, i];
    if (item.children) {
      return {
        ...item,
        path: _path,
        children: treeIndexGenerator(item.children, _path),
      };
    }
    return {
      ...item,
      path: _path,
    };
  });
}

/**
 * @description 根据路径寻找树节点
 * @export
 * @param {Array} treeData
 * @param {Array} path
 * @return {*}
 */
export function findTreeNodeByPath(treeData, path) {
  let temp;
  if (Array.isArray(path)) {
    path.forEach((index, i) => {
      if (i > 0) {
        temp = temp?.children && temp.children[index];
      } else {
        temp = treeData[index];
      }
    });
  }
  return temp;
}

/**
 * @description 根据key寻找tree node
 * @export
 * @param {*} treeData {label: string, value: string, children: array}
 * @param {*} key
 */
export function findTreeNodeByKey(treeData, key, _map = {}) {
  const defaultMap = { keyName: 'value', childrenName: 'children' };
  const map = { ...defaultMap, ..._map };
  let res;
  const recursion = (tree, k) => {
    if (res) return;
    for (let i = 0; i < tree.length; i++) {
      const item = tree[i];
      if (item[map.keyName] === k) {
        res = item;
        break;
      }
      if (isPowerArray(item[map.childrenName])) {
        recursion(item[map.childrenName], k);
      }
    }
  };
  recursion(treeData, key);
  return res;
}

/**
 * @description 数组交换元素
 * @export
 * @param {Array} arr 数组
 * @param {number} index1 元素1索引
 * @param {number} index2 元素2索引
 * @return {Array}
 */
export function swapTwoArrayItems(arr, index1, index2) {
  if (index1 < 0 || index2 < 0 || index1 === index2) return arr;
  // eslint-disable-next-line no-param-reassign, prefer-destructuring
  arr[index1] = arr.splice(index2, 1, arr[index1])[0];
  return arr;
}

// 评标工具：判断
export const tablePDGenerator = (arr2d) => {
  const container = document.createElement('div');
  const table = (
    <table>
      <tbody>
        <tr>
          <th colSpan={2}>
            <p style={{ textAlign: 'center' }}>条款号</p>
          </th>
          <th colSpan={1}>
            <p style={{ textAlign: 'center' }}>评审因素</p>
          </th>
          <th colSpan={1}>
            <p style={{ textAlign: 'center' }}>评审标准</p>
          </th>
        </tr>
        {arr2d.map((item1d, i) => item1d.map((item2d, j) => {
          if (j === 0) {
            return (
              <tr key={item2d.elementId}>
                <td rowSpan={item1d.length}>
                  <p style={{ textAlign: 'center' }}>
                    {`${item2d.initialNo}.${i + 1}`}
                  </p>
                </td>
                <td rowSpan={item1d.length}>
                  <p style={{ textAlign: 'center' }}>{item2d.elementType}</p>
                </td>
                <td rowSpan={1}>
                  <p style={{ textAlign: 'center' }}>{item2d.elementYinsu}</p>
                </td>
                <td rowSpan={1}>
                  <p style={{ textAlign: 'center' }}>{item2d.standardText}</p>
                </td>
              </tr>
            );
          }
          return (
            <tr key={item2d.elementId}>
              <td rowSpan={1}>
                <p style={{ textAlign: 'center' }}>{item2d.elementYinsu}</p>
              </td>
              <td rowSpan={1}>
                <p style={{ textAlign: 'center' }}>{item2d.standardText}</p>
              </td>
            </tr>
          );
        }))}
      </tbody>
    </table>
  );
  ReactDOM.render(table, container);
  return container.outerHTML;
};

// 评标工具：评分
export const tablePFGenerator = (arr2d) => {
  const container = document.createElement('div');
  const table = (
    <table>
      <tbody>
        <tr>
          <th colSpan={2}>
            <p style={{ textAlign: 'center' }}>条款号</p>
          </th>
          <th colSpan={1}>
            <p style={{ textAlign: 'center' }}>量化因素</p>
          </th>
          <th colSpan={1}>
            <p style={{ textAlign: 'center' }}>量化标准</p>
          </th>
        </tr>
        {arr2d.map((item1d, i) => item1d.map((item2d, j) => {
          if (j === 0) {
            return (
              <tr key={item2d.elementId}>
                <td rowSpan={item1d.length}>
                  <p style={{ textAlign: 'center' }}>
                    {`${item2d.initialNo}.${i + 1}`}
                  </p>
                </td>
                <td rowSpan={item1d.length}>
                  <p style={{ textAlign: 'center' }}>{item2d.elementType}</p>
                </td>
                <td rowSpan={1}>
                  <p style={{ textAlign: 'center' }}>{item2d.quantizationYinsu}</p>
                </td>
                <td rowSpan={1}>
                  <p style={{ textAlign: 'center' }}>{item2d.quantizationRules}</p>
                </td>
              </tr>
            );
          }
          return (
            <tr key={item2d.elementId}>
              <td rowSpan={1}>
                <p style={{ textAlign: 'center' }}>{item2d.quantizationYinsu}</p>
              </td>
              <td rowSpan={1}>
                <p style={{ textAlign: 'center' }}>{item2d.quantizationRules}</p>
              </td>
            </tr>
          );
        }))}
      </tbody>
    </table>
  );
  ReactDOM.render(table, container);
  return container.outerHTML;
};

export const getScrollBarWidth = (options) => {
  const { scrollbarClassName } = options ?? {};
  const outer = document.createElement('div');
  outer.style.overflow = 'scroll';
  outer.style.height = '200px';
  outer.style.width = '100px';
  if (scrollbarClassName) outer.className = scrollbarClassName;
  document.body.appendChild(outer);
  const widthNoScroll = outer.offsetWidth;
  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);
  const widthWithScroll = inner.offsetWidth;
  const scrollBarWidth = widthNoScroll - widthWithScroll;
  outer.remove();
  return scrollBarWidth;
};

/**
 * @description 业务工具方法：修饰目录数据
 * @param {Array} tree
 * @return {Array}
 */
export const catalogGenerator = (tree = []) => {
  const recursion = (arr) => isPowerArray(arr)
    && arr.map((item) => {
      if (item.children && isPowerArray(item.children)) {
        return {
          ...item,
          key: item.outlineId,
          title: item.outlineText,
          color: item.color,
          outlineReplaceGroupId: item.outlineReplaceGroupId,
          children: recursion(item.children),
        };
      }
      return {
        ...item,
        key: item.outlineId,
        title: item.outlineText,
        color: item.color,
        outlineReplaceGroupId: item.outlineReplaceGroupId,
      };
    });
  return recursion(tree) ?? [];
};

export const getRedTextFromHtml = (html) => {
  const container = document.createElement('div');
  container.innerHTML = html;
  const redTargets = container.querySelectorAll('[style*="color: red;"]');
  const innerText = Array.from(redTargets)
    .map((item) => item.innerText)
    .join('');
  return innerText;
};

export const getUnitNode = (unitTree, key) => {
  let node = null;
  const recursion = (tree) => {
    tree.forEach((n) => {
      if (n.value === key) {
        node = n;
      } else if (n.children) {
        recursion(n.children);
      }
    });
  };
  recursion(unitTree);
  return node;
};

// 判断非空情况：包含所有类型
export const isNotEmpty = (value) => {
  if (value === undefined || value === null) return false;
  if (typeof value === 'boolean') return true;
  if (typeof value === 'string') return value.trim() !== '';
  if (typeof value === 'number') return !Number.isNaN(value);
  if (Array.isArray(value)) return value.length > 0;
  if (typeof value === 'object') return Object.keys(value).length > 0;
  return false;
};

// 判断浏览器是否支持全屏
export const isFullscreenEnabled = () => {
  const fullscreenEnabled = document.fullscreenEnabled
    || document.webkitFullscreenEnabled
    || document.mozFullScreenEnabled
    || document.msFullscreenEnabled;
  return Boolean(fullscreenEnabled);
};

// 判断浏览器是否全屏
export const isFullscreen = () => {
  const fullscreenElement = document.fullscreenElement
    || document.webkitFullscreenElement
    || document.mozFullScreenElement
    || document.msFullscreenElement;
  return Boolean(fullscreenElement);
};

// 请求浏览器全屏
export const requestFullscreen = (element = document.body) => {
  const _requestFullscreen = element.requestFullscreen
    || element.webkitRequestFullscreen
    || element.mozRequestFullScreen
    || element.msRequestFullscreen;
  if (_requestFullscreen) {
    _requestFullscreen.call(element);
  }
};

// 退出浏览器全屏
export const exitFullscreen = () => {
  const _exitFullscreen = document.exitFullscreen
    || document.webkitExitFullscreen
    || document.mozCancelFullScreen
    || document.msExitFullscreen;
  if (_exitFullscreen) {
    _exitFullscreen.call(document);
  }
};
