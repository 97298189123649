import { atom, selector } from 'recoil';
import { apiGetFileEditorHtml } from '@/service';
import { message } from 'antd';

const preview = {
  params4EditorHtml: atom({
    key: 'params4EditorHtml',
    value: null,
  }),
  editorHtml: selector({
    key: 'editorHtml',
    get: async ({ get }) => {
      const params = get(preview.params4EditorHtml);
      if (!params) return null;
      const { data, err } = await apiGetFileEditorHtml(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
};

export default preview;
