import request from '@/utils/request';

/**
 * @description 获取系统参数列表
 * @export
 * @param {object} params
 * @param {string} params.isPaged 是否分页
 * @param {string} params.paramsClassify 参数库 我的参数1 企业参数2 系统参数3 文章4
 * @param {string} params.pageNum
 * @param {string} params.pageSize
 * @param {string} params.paramsUseSaturation 文件类型
 * @param {string} params.paramNameLike 模糊查询关键字
 * @return {Array}
 */
export function apiGetParameterList(data) {
  return request({
    method: 'post',
    url: '/web/file/getParam',
    data,
  });
}

/**
 * @description 修改参数
 * @export
 * @param {object} params
 * @param {string} params.paramsUuid
 * @param {string} params.paramsClassify
 * @param {string} params.paramsName
 * @param {string} params.paramsDesc
 * @param {string} params.paramsUseSceneId
 * @param {string} params.paramsTypeId
 * @param {string} params.paramsGroupId 数组分类
 * @param {Array} params.paramsRange 值域
 * @param {string} params.isNecessary
 * @param {string} params.isUnderLine
 * @param {string} params.dataSource
 * @return {*}
 */
export function apiUpdateParameter(data) {
  return request({
    method: 'post',
    url: '/web/file/updateParam',
    data,
  });
}

/**
 * @description
 * @export
 * @param {object} params
 * @param {string} params.paramsClassify
 * @param {string} params.paramsName
 * @param {string} params.paramsDesc
 * @param {string} params.paramsUseSceneId
 * @param {string} params.paramsTypeId
 * @param {string} params.isNecessary
 * @param {string} params.isUnderLine
 * @param {string} params.dataSource
 * @return {*}
 */
export function apiInsertParameter(data) {
  return request({
    method: 'post',
    url: '/web/file/addParam',
    data,
  });
}

/**
 * @description 删除参数
 * @export
 * @param {object} params
 * @param {string} params.paramsUuid
 * @return {*}
 */
export function apiDeleteParameter(params) {
  return request({
    method: 'get',
    url: '/web/file/delParam',
    params,
  });
}

/**
 * @description 获取参数类型
 * @export
 * @param {*} params
 * @return {*}
 */
export function apiGetParameterTypeList(params) {
  return request({
    method: 'get',
    url: '/web/file/getParamTypeDi',
    params,
  });
}

/**
 * @description 获取填参角色
 * @export
 * @param {*} params
 * @return {*}
 */
export function apiGetParameterSceneList(params) {
  return request({
    method: 'get',
    url: '/web/file/getParamSceneDi',
    params,
  });
}

/**
 * @description 获取数据来源
 * @export
 * @param {*} params
 * @return {*}
 */
// export function apiGetParameterSourceList(params) {
//   return request({
//     method: 'get',
//     url: '/web/file/getParamSourceDi',
//     params,
//   });
// }

/**
 * @description 获取参数分组列表
 * @export
 * @param {object} params
 * @param {string} [params.fileUuid]
 * @param {string} [params.fileVersionId]
 * @return {*}
 */
export function apiGetParameterGroupList(params) {
  return request({
    method: 'get',
    url: '/web/file/getParamGroupDi',
    params,
  });
}

/**
 * @description 获取参数类型下的展示格式列表
 * @export
 * @param {object} params
 * @param {string} params.paramsTypeId 参数类型
 * @return {Array}
 */
export function apiGetParameterStyleList(params) {
  return request({
    method: 'get',
    url: '/web/file/getParamTypeStyleList',
    params,
  });
}

/**
 * @description 获取单位名称字典
 * @export
 * @return {*}
 */
export function apiGetUnitTreeList(data) {
  return request({
    method: 'post',
    url: '/web/file/getUnitList',
    data,
  });
}

/**
 * @description 创建辅助工具模型
 * @export
 * @return {*}
 */
export function apiCreateSupTable(data) {
  return request({
    method: 'post',
    url: '/worktable/newSupTable',
    data,
  });
}

/**
 * @description 辅助工具-删除模型
 * @export
 * @param {object} data
 * @param {string} data.tableId
 * @return {*}
 */
export function apiDelSupTable(data) {
  return request({
    method: 'post',
    url: '/worktable/delSupTable',
    data,
  });
}

/**
 * @description 辅助工具-修改模型
 * @export
 * @param {object} data
 * @param {string} data.tableId 模型id
 * @param {string} data.title 模型名称
 * @param {string} data.enabled 是否启用
 * @param {object[]} data.columns 辅助工具模型字段
 * @return {*}
 */
export function apiUpdateSupTable(data) {
  return request({
    method: 'post',
    url: '/worktable/updateSupTable',
    data,
  });
}

/**
 * @description 辅助工具-查询模型列表
 * @export
 * @param {object} data
 * @param {string} data.isPaged
 * @param {string} data.pageNum
 * @param {string} data.pageSize
 * @param {string} data.paramNameLike
 * @return {*}
 */
export function apiGetSupTableList(data) {
  return request({
    method: 'post',
    url: '/worktable/getSupTableList',
    data,
  });
}

/**
 * @description 辅助工具-新增模型实例
 * @export
 * @param {object} data
 * @param {string} data.tableId 模型id
 * @param {string} data.formName 实例名称
 * @param {object} data.formValue 实例数据
 * @return {*}
 */
export function apiInsertSupTableItem(data) {
  return request({
    method: 'post',
    url: '/worktable/newSupTableForm',
    data,
  });
}

/**
 * @description 辅助工具-删除实例
 * @export
 * @param {object} data
 * @param {string} data.formId 实例id
 * @return {*}
 */
export function apiDelSupTableItem(data) {
  return request({
    method: 'post',
    url: '/worktable/delSupTableForm',
    data,
  });
}

/**
 * @description 辅助工具-修改实例
 * @export
 * @param {object} data
 * @param {string} data.formId
 * @param {string} data.formName
 * @param {object} data.formValue
 * @return {*}
 */
export function apiUpdateSupTableItem(data) {
  return request({
    method: 'post',
    url: '/worktable/updateSupTableForm',
    data,
  });
}

/**
 * @description 辅助工具-查询实例列表
 * @export
 * @param {object} data
 * @param {string} data.tableId 模型id
 * @param {string} data.isPaged
 * @param {string} data.pageNum
 * @param {string} data.pageSize
 * @param {string} data.paramNameLike
 * @return {*}
 */
export function apiGetSupTableInstanceList(data) {
  return request({
    method: 'post',
    url: '/worktable/getSupTableFormList',
    data,
  });
}

/**
 * @description 下载辅助工具模板
 * @export
 * @param {*} data
 * @param {*} data.tableId
 * @param {*} data.formIdList
 * @return {*}
 */
export function apiDownloadSupTableTemp(data) {
  return request({
    method: 'post',
    url: '/worktable/downloadSupTableExcel',
    data,
    original: true,
    timeout: 0,
    responseType: 'blob',
  });
}

/**
 * @description 辅助工具-新增标签
 * @export
 * @param {object} data
 * @param {string} data.label
 * @return {*}
 */
export function apiAddSupLabel(data) {
  return request({
    method: 'post',
    url: '/worktable/addSupLabel',
    data,
  });
}

/**
 * @description 辅助工具-获取标签
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetSupLabelList(data) {
  return request({
    method: 'post',
    url: '/worktable/getSupLabelList',
    data,
  });
}

/**
 * @description 辅助工具-删除标签
 * @export
 * @param {*} data
 * @param {*} data.uuid
 * @return {*}
 */
export function apiDeleteSupLabel(data) {
  return request({
    method: 'post',
    url: '/worktable/delSupLabel',
    data,
  });
}

/**
 * @description 获取母版下拉列表 label value
 * @export
 * @return {*}
 */
export function apiGetOriginTempLv() {
  return request({
    method: 'post',
    url: '/worktable/getMasterModelLV',
  });
}

/**
 * @description 获取数据库树
 * @export
 * @param {object} data
 * @param {string} [data.fileUuid] 文内数据
 * @param {string} [data.fileVersionId] 文内数据
 * @param {string} [data.level] 获取到第几级
 */
export function apiGetDatabaseTreeList(data) {
  return request({
    method: 'post',
    url: '/worktable/getDmDbList',
    data,
  });
}

/**
 * @description 复制表（复制表结构，复制表数据）
 * @export
 * @param {object} data
 * @param {string} data.key 表id
 * @param {string} data.tableName 重命名
 * @param {string} data.targetDb 复制到目标数据库
 * @param {boolean} data.includeData 是否携带数据
 */
export function apiCopyTable(data) {
  return request({
    method: 'post',
    url: '/worktable/copyTable',
    data,
  });
}

/**
 * @description 表内导入数据
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.fromTableId 来源表
 * @param {string} data.toTableId 目标表
 */
export function apiImportTableInnerData(data) {
  return request({
    method: 'post',
    url: '/worktable/importOnlyData',
    data,
  });
}

/**
 * @description 创建数据库
 * @export
 * @param {object} data
 * @param {string} data.title 数据库名称
 * @param {string} data.desc 数据库描述
 * @return {*}
 */
export function apiCreateDatabase(data) {
  return request({
    method: 'post',
    url: '/worktable/createDmDb',
    data,
  });
}

/**
 * @description 修改数据库
 * @export
 * @param {object} data
 * @param {string} data.key 数据库id
 * @param {string} data.title 数据库名称
 * @param {string} data.desc 数据库描述
 * @return {*}
 */
export function apiUpdateDatabase(data) {
  return request({
    method: 'post',
    url: '/worktable/chgDmDb',
    data,
  });
}

/**
 * @description 删除数据库
 * @export
 * @param {object} data
 * @param {string} data.key
 * @return {*}
 */
export function apiDeleteDatabase(data) {
  return request({
    method: 'post',
    url: '/worktable/delDmDb',
    data,
  });
}

/**
 * @description 创建表
 * @export
 * @param {object} data
 * @param {string} data.dbId 数据库id
 * @param {string} data.title 表名称
 * @param {string} data.desc 表描述
 * @return {*}
 */
export function apiCreateTable(data) {
  return request({
    method: 'post',
    url: '/worktable/createDmTable',
    data,
  });
}

/**
 * @description 修改表
 * @export
 * @param {object} data
 * @param {string} data.key
 * @param {string} data.title
 * @param {string} data.desc
 * @return {*}
 */
export function apiUpdateTable(data) {
  return request({
    method: 'post',
    url: '/worktable/chgDmTable',
    data,
  });
}

/**
 * @description 删除数据表
 * @export
 * @param {object} data
 * @param {string} data.key
 * @return {*}
 */
export function apiDeleteTable(data) {
  return request({
    method: 'post',
    url: '/worktable/delDmTable',
    data,
  });
}

/**
 * @description 新增数据表字段
 * @export
 * @param {object} data
 * @param {string} data.key 数据表id
 * @param {string} data.title 字段名称
 * @param {string} data.dataIndex 字段标识
 * @param {string} data.fieldType 字段类型
 * @param {string} [data.options] 字段选项
 * @param {string} [data.unit] 字段单位
 * @return {*}
 */
export function apiAddTableField(data) {
  return request({
    method: 'post',
    url: '/worktable/addDmTableColumns',
    data,
  });
}

/**
 * @description 删除数据表字段
 * @export
 * @param {object} data
 * @param {string} data.key 字段key
 * @return {*}
 */
export function apiDeleteTableField(data) {
  return request({
    method: 'post',
    url: '/worktable/delDmTableColumns',
    data,
  });
}

/**
 * @description 更新数据表字段
 * @export
 * @param {object} data
 * @param {string} data.key 字段key
 * @param {string} data.title 字段名称
 * @param {string} data.dataIndex 字段标识
 * @param {string} data.fieldType 字段类型
 * @param {string} [data.options] 字段选项
 * @param {string} [data.unit] 字段单位
 * @return {*}
 */
export function apiUpdateTableField(data) {
  return request({
    method: 'post',
    url: '/worktable/chgDmTableColumns',
    data,
  });
}

/**
 * @description 获取数据表字段列表
 * @export
 * @param {object} data
 * @param {string} data.key 数据表id
 * @return {*}
 */
export function apiGetTableFieldList(data) {
  return request({
    method: 'post',
    url: '/worktable/getDmTableColumnsList',
    data,
  });
}

/**
 * @description 获取表内容
 * @export
 * @param {object} data
 * @param {string} data.key 数据表id
 * @param {Array<Record<string,any>>} [data.filter] 过滤条件
 * @param {Array<Record<string,any>>} [data.sort] 排序条件
 * @param {string} [data.pageSize] 每页条数
 * @param {string} [data.pageNum] 页码
 * @return {*}
 */
export function apiGetTableRowList(data) {
  return request({
    method: 'post',
    url: '/worktable/getDmTableContent',
    data,
  });
}

/**
 * @description 获取表的总数据量
 * @export
 * @param {object} data
 * @param {object} data.key 数据表id
 * @return {*}
 */
export function apiGetTableRowTotalNum(data) {
  return request({
    method: 'post',
    url: '/worktable/getDmDataTotal',
    data,
  });
}

/**
 * @description 新增行数据
 * @export
 * @param {object} data
 * @param {string} data.tableId 数据表id
 * @param {object} data.dataContent 行数据
 * @return {*}
 */
export function apiInsertTableRow(data) {
  return request({
    method: 'post',
    url: '/worktable/addDmData',
    data,
  });
}

/**
 * @description 更新行数据
 * @export
 * @param {object} data
 * @param {string} data.dataId 数据行id
 * @param {string} data.dataContent 数据内容
 * @return {*}
 */
export function apiUpdateTableRow(data) {
  return request({
    method: 'post',
    url: '/worktable/chgDmData',
    data,
  });
}

/**
 * @description 删除行数据
 * @export
 * @param {object} data
 * @param {string} data.tableId 数据表id
 * @param {string} data.dataId 数据行id
 */
export function apiDeleteTableRow(data) {
  return request({
    method: 'post',
    url: '/worktable/delDmData',
    data,
  });
}

/**
 * @description 整体配置列
 * @export
 * @param {object} data
 * @param {string} data.tableId 数据表id
 * @param {boolean} [data.showPagination ] 是否分页
 * @param {boolean} [data.ellipsis  ] 是否缩略展示
 * @param {any[]} [data.columns] 整体列配置：排序/显示
 * @return {*}
 */
export function apiUpdateTableColumns(data) {
  return request({
    method: 'post',
    url: '/worktable/orderDmTableColumns',
    data,
  });
}

/**
 * @description 保存查询结果成新表
 * @export
 * @param {object} data
 * @param {string} data.key
 * @param {string} [data.fileUuid] 文内标记
 * @param {string} [data.fileVersionId] 文内标记
 * @param {string} [data.tableName] 表名称
 * @param {string} [data.groupBy] 聚合字段
 * @param {string} [data.groupByFunctions] 聚合函数
 * @param {string} [data.filters] 过滤条件
 * @param {string} [data.sort] 排序条件
 * @param {boolean} [data.overwrite] 覆写原表
 */
export function apiSaveResultToNewTable(data) {
  return request({
    method: 'post',
    url: '/worktable/saveDmData',
    data,
  });
}

/**
 * @description 保存查询结果成新视图
 * @export
 * @param {object} data
 * @param {string} data.key
 * @param {string} [data.fileUuid] 文内标记
 * @param {string} [data.fileVersionId] 文内标记
 * @param {string} [data.viewName] 视图名称
 * @param {string} [data.groupBy] 聚合字段
 * @param {string} [data.groupByFunctions] 聚合函数
 * @param {string} [data.filters] 过滤条件
 * @param {string} [data.sort] 排序条件
 * @param {boolean} [data.overwrite] 覆写原表
 */
export function apiSaveResultToNewView(data) {
  return request({
    method: 'post',
    url: '/worktable/saveDmView',
    data,
  });
}

/**
 * @description 下载表格到下载中心
 * @export
 * @param {object} params
 * @param {string} params.key
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 */
export function apiDownloadTableData(params) {
  return request({
    method: 'get',
    url: '/worktable/downloadDmData',
    params,
  });
}

/**
 * @description 导入数据表
 * @export
 * @param {object} data
 * @param {string} data.key 数据库id(create)/数据表id(append)
 * @param {'append'|'create'} data.type 导入数据新增方式 创建新表/追加数据
 * @param {File} [data.file]
 * @param {string} [data.database]
 * @param {string} [data.desc]
 * @return {*}
 */
export function apiImportTableData(data) {
  const formdata = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (value) formdata.append(key, value);
  });
  return request({
    method: 'post',
    url: '/worktable/uploadDmData',
    timeout: 0, // 永不超时
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formdata,
  });
}

/**
 * @description 同步视图数据
 * @export
 * @param {object} data
 * @param {string} data.key 视图id
 */
export function apiSyncTableView(data) {
  return request({
    method: 'post',
    url: '/worktable/rerunViewData',
    data,
  });
}

/**
 * @description 更新视图生成规则
 * @export
 * @param {object} data
 * @param {string} data.key 视图id
 * @param {object} data.condition 视图生成规则
 */
export function apiUpdateViewCondition(data) {
  return request({
    method: 'post',
    url: '/worktable/updateViewCondition',
    data,
  });
}
