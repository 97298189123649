import request from '@/utils/request';

/**
 * @description 新增分组
 * @export
 * @param {object} params
 * @param {string} params.groupName
 * @return {*}
 */
export function apiAddParamGroup(params) {
  return request({
    method: 'get',
    url: '/web/file/addParamGroup',
    params,
  });
}

/**
 * @description 删除分组项
 * @export
 * @param {object} params
 * @param {string} params.groupId
 * @return {*}
 */
export function apiDelParamGroup(params) {
  return request({
    method: 'get',
    url: '/web/file/delParamGroup',
    params,
  });
}

/**
 * @description 更新分组信息
 * @export
 * @param {object} params
 * @param {string} params.groupId
 * @param {string} params.groupName
 * @return {*}
 */
export function apiUpdateParamGroup(params) {
  return request({
    method: 'get',
    url: '/web/file/updateParamGroup',
    params,
  });
}

/**
 * @description 获取分组列表
 * @export
 * @param {object} params
 * @return {Array}
 */
export function apiGetParamGroupList(params) {
  return request({
    method: 'get',
    url: '/web/file/getParamGroupList',
    params,
  });
}

/**
 * @description 新增词条
 * @export
 * @param {object} data
 * @param {string} data.wordsName
 * @param {string} data.wordsDesc
 * @return {*}
 */
export function apiAddWord(data) {
  return request({
    method: 'post',
    url: '/web/file/addWords',
    data,
  });
}

/**
 * @description 删除词条
 * @export
 * @param {object} params
 * @param {string} params.wordsUuid
 * @return {*}
 */
export function apiDelWord(params) {
  return request({
    method: 'get',
    url: '/web/file/delWords',
    params,
  });
}

/**
 * @description 更新词条
 * @export
 * @param {object} data
 * @param {string} data.wordsUuid
 * @param {string} data.wordsName
 * @param {string} data.wordsDesc
 * @return {*}
 */
export function apiUpdateWord(data) {
  return request({
    method: 'post',
    url: '/web/file/updateWords',
    data,
  });
}

/**
 * @description 获取获取词条列表
 * @export
 * @param {object} params
 * @param {string} params.isPaged
 * @param {string} params.pageNum
 * @param {string} params.pageSize
 * @param {string} params.paramNameLike
 * @return {Array}
 */
export function apiGetWordList(params) {
  return request({
    method: 'get',
    url: '/web/file/getWordsList',
    params,
  });
}

/**
 * @description 新增标签分组
 * @export
 * @param {object} params
 * @param {string} params.labelGroupName 分组名称
 * @return {*}
 */
export function apiInsertLabelGroup(params) {
  return request({
    method: 'get',
    url: '/web/file/addLabelGroup',
    params,
  });
}

/**
 * @description 删除标签分组
 * @export
 * @param {object} params
 * @param {string} params.labelGroupId
 * @return {*}
 */
export function apiDelLabelGroup(params) {
  return request({
    method: 'get',
    url: '/web/file/delLabelGroup',
    params,
  });
}

/**
 * @description 获取标签组列表
 * @export
 * @param {*} params
 * @return {Array}
 */
export function apiGetLabelGroupList(params) {
  return request({
    method: 'get',
    url: '/web/file/getLabelGroupList',
    params,
  });
}

/**
 * @description 新增标签
 * @export
 * @param {object} params
 * @param {string} params.labelContent 标签名
 * @param {string} params.labelGroupId 标签组
 * @param {Array} params.fileTypeId 类型-映射文件类型
 * @return {*}
 */
export function apiInsertLabel(data) {
  return request({
    method: 'post',
    url: '/web/file/addLabel',
    data,
  });
}

/**
 * @description 删除标签
 * @export
 * @param {object} params
 * @param {string} params.labelUuid
 * @return {*}
 */
export function apiDelLabel(params) {
  return request({
    method: 'get',
    url: '/web/file/delLabel',
    params,
  });
}

/**
 * @description 更新标签
 * @export
 * @param {object} params
 * @param {string} params.labelUuid
 * @param {string} params.labelContent
 * @param {string} params.labelGroupId
 * @param {Array} params.fileTypeId
 * @return {*}
 */
export function apiUpdateLabel(data) {
  return request({
    method: 'post',
    url: '/web/file/updateLabel',
    data,
  });
}

/**
 * @description 获取标签列表
 * @export
 * @param {object} params
 * @param {string} params.labelGroupId 筛选分组
 * @param {string} params.isPaged
 * @param {string} params.pageSize
 * @param {string} params.pageNum
 * @param {string} params.paramNameLike 模糊查询关键字
 * @return {Array}
 */
export function apiGetLabelList(params) {
  return request({
    method: 'get',
    url: '/web/file/getLabelList',
    params,
  });
}

/**
 * @description 参数场景文件类型
 * @export
 * @param {*} params
 * @return {*}
 */
export function apiGetParamSaturationList(params) {
  return request({
    method: 'get',
    url: '/web/file/getParamSaturationList',
    params,
  });
}

/**
 * @description 上传文档
 * @export
 * @param {object} data
 * @param {FormData} data.file
 * @param {string} data.fileTypeId
 * @param {Array} data.fileLabelList
 * @return {*}
 */
export function apiUploadDocument(data) {
  return request({
    method: 'post',
    url: '/web/fileindex/fileupload',
    data,
  });
}

/**
 * @description 获取母版列表
 * @export
 * @param {object} params
 * @param {string} params.fileTypeId 类型筛选
 * @param {string} params.fileLabelList 标签筛选
 * @param {string} params.isPaged
 * @param {string} params.pageSize
 * @param {string} params.pageNum
 * @param {string} params.paramNameLike 模糊查询关键字
 * @return {Array}
 */
export function apiGetOriginalTempPageList(data) {
  return request({
    method: 'POST',
    url: '/worktable/getMasterModel',
    data,
  });
}

/**
 * @description 查询文档管理列表
 * @export
 * @param {object} data
 * @param {string} data.isPaged
 * @param {string} data.pageNum
 * @param {string} data.pageSize
 * @param {string} data.paramNameLike
 * @return {*}
 */
export function apiGetFileM8tList(data) {
  return request({
    method: 'POST',
    url: '/worktable/getLibraryFileManagementList',
    data,
  });
}

/**
 * @description 更新文档管理项
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} [data.fileVersionId] 上线必传，下线不必传
 * @param {string} data.fileStatus 枚举值 online offline
 * @return {*}
 */
export function apiUpdateFileM8tItem(data) {
  return request({
    method: 'POST',
    url: '/worktable/changeLibraryFileStatus',
    data,
  });
}

/**
 * @description 更改推荐
 * @export
 * @param {*} data
 * @param {*} data.fileUuid
 * @param {*} data.isRecommend 0/1
 * @return {*}
 */
export function apiUpdateFileM8tRecommendItem(data) {
  return request({
    method: 'POST',
    url: '/worktable/changeLibraryFileRecommend',
    data,
  });
}

/**
 * @description 删除文档管理项
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @return {*}
 */
export function apiDelFileM8tItem(data) {
  return request({
    method: 'POST',
    url: '/worktable/deleteLibraryFile',
    data,
  });
}

/**
 * @description 文档审核列表
 * @export
 * @param {object} data
 * @param {string} data.isPaged
 * @param {string} data.pageNum
 * @param {string} data.pageSize
 * @param {string} data.paramNameLike
 * @return {*}
 */
export function apiFileAuditList(data) {
  return request({
    method: 'POST',
    url: '/worktable/getAuditingFileList',
    data,
  });
}

/**
 * @description 更新文件审核内容
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.auditingStatus 枚举值 通过/不通过
 * @param {string} data.auditingReason 审核意见
 * @return {*}
 */
export function apiUpdateFileAuditItem(data) {
  return request({
    method: 'POST',
    url: '/worktable/changeAuditingFileStatus',
    data,
  });
}

/**
 * @description 获取用户列表
 * @export
 * @param {string} data.isPaged
 * @param {string} data.pageNum
 * @param {string} data.pageSize
 * @param {string} data.paramNameLike
 * @return {*}
 */
export function apiGetUserTableList(data) {
  return request({
    method: 'POST',
    url: '/worktable/getUserList',
    data,
  });
}

/**
 * @description 新增用户
 * @export
 * @param {object} data
 * @param {string} data.userName
 * @param {string} data.userPhone
 * @param {string} data.rolesId
 * @return {*}
 */
export function apiAddUserItem(data) {
  return request({
    method: 'POST',
    url: '/worktable/addUser',
    data,
  });
}

/**
 * @description 更新用户信息
 * @export
 * @param {object} data
 * @param {string} data.userId
 * @param {string} data.userName
 * @param {string} data.userPhone
 * @param {string} data.rolesId
 * @return {*}
 */
export function apiUpdateUserItem(data) {
  return request({
    method: 'POST',
    url: '/worktable/updateUser',
    data,
  });
}

/**
 * @description 删除用户
 * @export
 * @param {object} data
 * @param {string} data.userId
 * @return {*}
 */
export function apiDeleteUserItem(data) {
  return request({
    method: 'POST',
    url: '/worktable/delUser',
    data,
  });
}

/**
 * @description 重置用户密码
 * @export
 * @param {object} data
 * @param {string} data.userId
 * @return {*}
 */
export function apiResetUserPwd(data) {
  return request({
    method: 'POST',
    url: '/worktable/resetPassword',
    data,
  });
}

/**
 * @description 获取角色
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetRolesLV(data) {
  return request({
    method: 'POST',
    url: '/worktable/getRoles',
    data,
  });
}

/**
 * @description 获取标签options
 * @export
 * @param {*} params
 * @param {*} [params.labelGroupId] 分组id
 * @return {*}
 */
export function apiGetLabelLV(params) {
  return request({
    method: 'GET',
    url: '/web/file/getLabelLVList',
    params,
  });
}

/**
 * @description 评审模型-新增模型
 * @export
 * @param {object} data
 * @param {string} data.assessName
 * @param {string} data.assessMethod
 * @param {string} data.assessLink
 * @param {string} data.assessType
 * @param {string} data.modelType
 * @param {Array} data.label
 * @return {*}
 */
export function apiAddEvaluateModel(data) {
  return request({
    method: 'POST',
    url: '/worktable/newAssessModel',
    data,
  });
}

/**
 * @description 评审模型管理-删除评审模型
 * @export
 * @param {object} data
 * @param {string} data.assessId
 * @return {*}
 */
export function apiDelEvaluateModel(data) {
  return request({
    method: 'POST',
    url: '/worktable/delAssessModel',
    data,
  });
}

/**
 * @description 评审模型管理-修改评审模型
 * @export
 * @param {object} data
 * @param {string} data.assessId
 * @param {string} data.assessName
 * @param {string} data.assessMethod
 * @param {string} data.assessLink
 * @param {string} data.assessType
 * @param {string} data.modelType
 * @param {Array<string>} data.label
 * @return {*}
 */
export function apiUpdateEvaluateModel(data) {
  return request({
    method: 'POST',
    url: '/worktable/updateAssessModel',
    data,
  });
}

/**
 * @description 评审模型管理-获取评审模型信息
 * @export
 * @param {object} data
 * @param {string} data.assessId
 * @return {*}
 */
export function apiGetEvaluateModelInfo(data) {
  return request({
    method: 'POST',
    url: '/worktable/getAssessModelInfo',
    data,
  });
}

/**
 * @description 评审模型管理-获取评审模型列表
 * @export
 * @param {object} data
 * @param {string} data.isPaged
 * @param {string} data.pageNum
 * @param {string} data.pageSize
 * @param {string} data.paramNameLike
 * @return {*}
 */
export function apiGetEvaluateModelList(data) {
  return request({
    method: 'POST',
    url: '/worktable/getAssessModelList',
    data,
  });
}

/**
 * @description 评审模型管理-新增评审元素
 * @export
 * @param {object} data
 * @param {string} data.assessId 模型
 * @param {string} data.elementType 评审标准
 * @param {Array} data.elementList 要素列表
 * @return {*}
 */
export function apiAddEvaluateModelElement(data) {
  return request({
    method: 'POST',
    url: '/worktable/newAssessModelElement',
    data,
  });
}

/**
 * @description 评审模型管理-删除评审元素
 * @export
 * @param {object} data
 * @param {string} data.assessId
 * @param {string} data.elementType
 * @return {*}
 */
export function apiDelEvaluateModelElement(data) {
  return request({
    method: 'POST',
    url: '/worktable/delAssessModelElement',
    data,
  });
}

/**
 * @description 评审模型管理-修改评审元素
 * @export
 * @param {object} data
 * @param {string} data.assessId
 * @param {string} data.elementType
 * @param {string} data.elementList
 * @return {*}
 */
export function apiUpdateEvaluateModelElement(data) {
  return request({
    method: 'POST',
    url: '/worktable/updateAssessModelElement',
    data,
  });
}

/**
 * @description 评审模型管理-获取评审元素列表
 * @export
 * @param {*} data
 * @param {*} data.type
 * @return {*}
 */
export function apiGetEvaluateModelElementList(data) {
  return request({
    method: 'POST',
    url: '/worktable/getAssessModelElementList',
    data,
  });
}

/**
 * @description 评审模型管理-获取评审元素信息
 * @export
 * @param {object} data
 * @param {string} data.assessId
 * @param {string} data.elementType
 * @return {*}
 */
export function apiGetEvaluateModelElementInfo(data) {
  return request({
    method: 'POST',
    url: '/worktable/getAssessModelElementInfo',
    data,
  });
}

/**
 * @description 评审模型管理-调整评审条款顺序
 * @export
 * @param {object} data
 * @param {string} data.assessId
 * @param {string} data.elementTypeList [评审标准类型list]
 * @return {*}
 */
export function apiUpdateEvaluateModelOrder(data) {
  return request({
    method: 'POST',
    url: '/worktable/updateAssessModelOrder',
    data,
  });
}

/**
 * @description 获取评标模型下拉菜单数据
 * @export
 * @param {object} data
 * @param {string} data.assessMethod 评审办法
 * @return {*}
 */
export function apiGetEvaluateModelOptions(data) {
  return request({
    method: 'POST',
    url: '/web/file/getAssessPlanList',
    data,
  });
}

/**
 * @description 评审模型管理-参数下拉列表
 * @export
 * @param {object} data
 * @param {string} data.paramsUseSceneId 10表示招标人参数;20表示投标人参数"
 * @return {*}
 */
export function apiGetParameterOptions(data) {
  return request({
    method: 'POST',
    url: '/worktable/getAssessModelParam',
    data,
  });
}

/**
 * @description 评审实例-获取文内已使用参数列表
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.paramsUseSceneId  10表示招标人参数;20表示投标人参数"
 * @return {*}
 */
export function apiGetDocParameterOptions(data) {
  return request({
    method: 'POST',
    url: '/web/file/getContentAssessParam',
    data,
  });
}

/**
 * @description 提交评审实例总体方案
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.assessMethod
 * @param {string} data.assessModelList
 * @param {string} data.scoreRat
 * @return {*}
 */
export function apiConfirmEvaluateInstance(data) {
  return request({
    method: 'POST',
    url: '/web/file/confirmAssessTotalPlan',
    data,
  });
}

/**
 * @description 查询评标实例下的评标因素列表
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.assessId 评审模型Id
 * @param {string} data.elementType 评审标准类型
 * @param {string} data.paramNameLike 模糊查询
 * @return {*}
 */
export function apiGetEvaluateInstanceFactorList(data) {
  return request({
    method: 'POST',
    url: '/web/file/getAssessElementList',
    data,
  });
}

/**
 * @description 获取总体方案中的方案列表：用于生成 tab 页
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @return {*}
 */
export function apiGetEvaluateInstanceTabList(data) {
  return request({
    method: 'POST',
    url: '/web/file/getAssessElementListInPlan',
    data,
  });
}

/**
 * @description 文档评审实例： 新增评标元素
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.assessId
 * @param {string} data.elementType
 * @param {string} 其他表单项
 * @return {*}
 */
export function apiAddEvaluateInstanceFactor(data) {
  return request({
    method: 'POST',
    url: '/web/file/addAssessElement',
    data,
  });
}

/**
 * @description 文档评审实例： 删除评标元素
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.assessId
 * @param {string} data.elementId
 * @return {*}
 */
export function apiDelEvaluateInstanceFactor(data) {
  return request({
    method: 'POST',
    url: '/web/file/delAssessElement',
    data,
  });
}

/**
 * @description 文档评审实例： 更新评标元素
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.assessId
 * @param {string} data.elementId
 * @param {string} data.elementType
 * @param {string} 其他表单项
 * @return {*}
 */
export function apiUpdateEvaluateInstanceFactor(data) {
  return request({
    method: 'POST',
    url: '/web/file/updateAssessElement',
    data,
  });
}

/**
 * @description 文档评审实例-获取总体方案内容
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @return {*}
 */
export function apiGetEvaluateInstanceInfo(data) {
  return request({
    method: 'POST',
    url: '/web/file/getAssessTotalPlan',
    data,
  });
}

/**
 * @description 文档评审实例-调整元素顺序
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.assessId
 * @param {string} data.elementIdList
 * @return {*}
 */
export function apiOrderEvaluateInstanceFactor(data) {
  return request({
    method: 'POST',
    url: '/web/file/orderAssessElement',
    data,
  });
}

/**
 * @description 文档评审实例-获取总体方案的二维表
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.check
 * @return {*}
 */
export function apiGetEvaluateInstanceTableData(data) {
  return request({
    method: 'POST',
    url: '/web/file/useAssessTotalPlan',
    data,
  });
}

/**
 * @description 同上：返回原始数据，方便处理非常规报错信息
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.check
 * @return {*}
 */
export function apiGetEvaluateInstanceOriginalTableData(data) {
  return request({
    method: 'POST',
    url: '/web/file/useAssessTotalPlan',
    data,
    original: true,
  });
}

/**
 * @description 评标工具-动态获取评审标准类型
 * @export
 * @param {object} params
 * @param {string} params.assessId 评审模型id
 * @param {string} params.fileUuid
 * @return {*}
 */
export function apiGetStandardTypeOptions(params) {
  return request({
    method: 'get',
    url: '/worktable/getElementTypeOptions',
    params,
  });
}

/**
 * @description 获取开启的创建类型列表
 * @export
 * @param {object} params
 * @param {string} params.fileTypeGroupId  WJ/MB
 * @return {*}
 */
export function apiGetDocTypeOptions(params) {
  return request({
    method: 'get',
    url: '/web/file/getFileTypeList',
    params,
  });
}

/**
 * @description 获取企业常用文档模板
 * @export
 * @param {object} params
 * @param {string} params.limit
 * @return {*}
 */
export function apiGetEnterpriseCommonFileList(params) {
  return request({
    method: 'post',
    url: '/worktable/getCompanyUseFileList',
    params,
  });
}

/**
 * @description 创建常用评审方案配置方案
 * @export
 * @param {object} params
 * @param {string} params.fastName 名称
 * @param {string} params.remarks 说明
 * @return {*}
 */
export function apiCreatePBCase(data) {
  return request({
    method: 'post',
    url: '/worktable/createFastAssess',
    data,
  });
}

/**
 * @description 获取常用评审方案配置方案列表
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetPBCaseList(data) {
  return request({
    method: 'post',
    url: '/worktable/getFastAssessList',
    data,
  });
}

/**
 * @description 修改常用评审方案配置方案
 * @export
 * @param {object} params
 * @param {object} params.fastId
 * @param {string} params.fastName 名称
 * @param {string} params.remarks 说明
 * @return {*}
 */
export function apiUpdatePBCase(data) {
  return request({
    method: 'post',
    url: '/worktable/updateFastAssess',
    data,
  });
}

/**
 * @description 删除评审方案模型
 * @export
 * @param {object} data
 * @param {string} data.fastId
 * @return {*}
 */
export function apiDeletePBCase(data) {
  return request({
    method: 'post',
    url: '/worktable/delFastAssess',
    data,
  });
}

/**
 * @description 获取常用评审方案配置方案下拉列表
 * @export
 * @return {*}
 */
export function apiGetPBCaseOptions() {
  return request({
    method: 'post',
    url: '/worktable/getFastAssessListLV',
  });
}

/**
 * @description 获取常用评审方案配置方案信息
 * @export
 * @return {*}
 */
export function apiGetPBCaseInfo(data) {
  return request({
    method: 'post',
    url: '/worktable/getFastAssessInfo',
    data,
  });
}

/**
 * @description 获取评审方案总览
 * @export
 * @param {object} data
 * @param {string} data.fastId
 * @return {*}
 */
export function apiGetPBCaseOverview(data) {
  return request({
    method: 'post',
    url: '/worktable/getFastAssessPreview',
    data,
  });
}

/**
 * @description 下拉菜单选项维护-获取下拉选项列表
 * @export
 * @param {object} data
 * @param {string} [data.type] 类型
 * @return {*}
 */
export function apiGetSystemOptionList(data) {
  return request({
    method: 'post',
    url: '/worktable/getOptionsList',
    data,
  });
}

/**
 * @description 下拉菜单选项维护-插入新下拉选项
 * @export
 * @param {object} data
 * @param {string} data.label
 * @param {string} data.type
 * @return {*}
 */
export function apiInsertSystemOption(data) {
  return request({
    method: 'post',
    url: '/worktable/addOptions',
    data,
  });
}

/**
 * @description 下拉菜单选项维护-删除下拉选项
 * @export
 * @param {object} data
 * @param {string} data.id
 * @return {*}
 */
export function apiDelSystemOption(data) {
  return request({
    method: 'post',
    url: '/worktable/delOptions',
    data,
  });
}

/**
 * @description 下拉菜单选项维护-获取下拉选项类型列表
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetSystemOptionTypeList(data) {
  return request({
    method: 'post',
    url: '/worktable/getOptionsTypeList',
    data,
  });
}

/**
 * @description 更新分组排序
 * @export
 * @param {object} data
 * @param {string[]} data.groupIdList
 * @return {*}
 */
export function apiOrderParamGroupList(data) {
  return request({
    method: 'post',
    url: '/web/file/orderParamGroup',
    data,
  });
}

/**
 * @description 获取视频教程列表
 * @export
 * @param {object} data
 * @param {string} data.isPaged
 * @param {string} data.pageNum
 * @param {string} data.pageSize
 * @param {string} data.paramNameLike
 */
export function apiGetTeachVideoList(data) {
  return request({
    method: 'post',
    url: '/worktable/getTeachVideoList',
    data,
  });
}

/**
 * @description 添加视频教程
 * @export
 * @param {object} data
 * @param {string} data.title
 * @param {string} data.description
 * @param {string} data.videoUrl
 */
export function apiAddTeachVideo(data) {
  return request({
    method: 'post',
    url: '/worktable/createTeachVideo',
    data,
  });
}

/**
 * @description 更新视频教程
 * @export
 * @param {object} data
 * @param {string} data.videoId
 * @param {string} [data.title]
 * @param {string} [data.description]
 * @param {string} [data.videoUrl]
 */
export function apiUpdateTeachVideo(data) {
  return request({
    method: 'post',
    url: '/worktable/updateTeachVideo',
    data,
  });
}

/**
 * @description 删除视频教程
 * @export
 * @param {object} data
 * @param {string} data.videoId
 */
export function apiDelTeachVideo(data) {
  return request({
    method: 'post',
    url: '/worktable/delTeachVideo',
    data,
  });
}

/**
 * @description 点赞视频教程
 * @export
 * @param {object} data
 * @param {string} data.videoId
 */
export function apiLikeTeachVideo(data) {
  return request({
    method: 'post',
    url: '/worktable/likeTeachVideo',
    data,
  });
}

/**
 * @description 点踩视频教程
 * @export
 * @param {object} data
 * @param {string} data.videoId
 */
export function apiDisLikeTeachVideo(data) {
  return request({
    method: 'post',
    url: '/worktable/dislikeTeachVideo',
    data,
  });
}
